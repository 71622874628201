import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import XYZSource from "ol/source/XYZ";
import { fromLonLat } from "ol/proj";

new Map({
  target: "map-container",
  layers: [
    new TileLayer({
      source: new XYZSource({
        url: "http://tile.stamen.com/terrain/{z}/{x}/{y}.jpg"
      })
    })
  ],
  view: new View({
    center: fromLonLat([0, 0]),
    zoom: 2
  })
});
